// let scrollPos;

$(function () {
  $('a[href^="#"]').click(function () {
    var adjust = 0;
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top + adjust;
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  // $(".punkt__spmenu-btn").on("click", function () {
  //   $(this).toggleClass("is-open");
  //   $(".punkt__spmenu-inner").toggleClass("is-open");
  //   if ($(this).hasClass("is-open")) {
  //     scrollPos = $(window).scrollTop();
  //     $("body").addClass("is-fixed").css({ top: -scrollPos });
  //   } else {
  //     $("body").removeClass("is-fixed").css({ top: 0 });
  //     $(window).scrollTop(scrollPos);
  //   }
  // });

  if ($("body").hasClass("production")) {
    $(".is-production").addClass("is-current");
  } else if ($("body").hasClass("sales")) {
    $(".is-sales").addClass("is-current");
  } else if ($("body").hasClass("support")) {
    $(".is-support").addClass("is-current");
  }

  $(".punkt__header-spbtn").on("click", function () {
    $(".punkt__header-spbtn").toggleClass("is-open");
    $(".punkt__header-spmenu").toggleClass("is-open");
  });
  $(".punkt__header-spmenu a").on("click", function () {
    $(".punkt__header-spbtn").toggleClass("is-open");
    $(".punkt__header-spmenu").toggleClass("is-open");
  });
});

// let currentPos = 0;
// let winScrollTop = 0;
// $(window).scroll(function () {
//   winScrollTop = $(window).scrollTop();
//   if ($(window).scrollTop() > 64) {
//     $(".punkt__header").addClass("is-hide");
//   } else {
//     $(".punkt__header").removeClass("is-hide");
//   }
//   if (winScrollTop <= currentPos) {
//     $(".punkt__header").removeClass("is-hide");
//   }
//   currentPos = winScrollTop;
// });
